import React from 'react'
import { Card, Col, Form, Row, message, Table, Select, Button, Input } from 'antd'
import Gaps from '../../components/Gaps'
import { Option } from 'antd/lib/mentions'
import { CopyOutlined, LinkOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import PurchaseOrderService from '../../network/services/purchaseOrder'
import TextArea from 'antd/lib/input/TextArea'

const PurchaseOrderForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  readonly = false
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'purchaseOrder' })
  const { t: generalT } = useTranslation('translation')

  const itemCol = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return `${record.product_variant.variant_name}`
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty'
    }
  ]

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update return status
      try {
        const { data: result } = await PurchaseOrderService.update(initialValue.id, values)
        if (result) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update order error ' + error.message)
      }
    }
    setIsLoading(false)
  }

  return (
    <div>
      <Form
        form={form}
        name="new-order"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={initialValue}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item name="status" label={generalT('status')}>
                <Select disabled={readonly}>
                  <Option key="pending" value="pending">
                    pending
                  </Option>
                  <Option key="fulfilled" value="fulfilled">
                    fulfilled
                  </Option>
                  <Option key="cancelled" value="cancelled">
                    cancelled
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item name="company" label={t('receiverCompany')} disabled={readonly}>
                <Input />
              </Form.Item>

              <Form.Item
                name="first_name"
                label={`${t('receiverName')} (${generalT('lastName')})`}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
                disabled={readonly}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="last_name"
                label={`${t('receiverName')} (${generalT('firstName')})`}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
                disabled={readonly}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="receiver_email"
                label={t('receiverEmail')}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
                disabled={readonly}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="phone"
                label={t('receiverPhone')}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
                disabled={readonly}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="address"
                label={t('receiverAddress')}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
                disabled={readonly}
              >
                <TextArea />
              </Form.Item>

              <Form.Item name="apartment" label={t('receiverApartment')} disabled={readonly}>
                <Input />
              </Form.Item>

              <Form.Item
                name="zip"
                label={t('receiverZipCode')}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
                disabled={readonly}
              >
                <Input />
              </Form.Item>

              <Form.Item name="remark" label={t('remark')} disabled={readonly}>
                <TextArea />
              </Form.Item>

              <div>
                <div>Line Items</div>
                <Table dataSource={initialValue.items} columns={itemCol} />
              </div>

              {initialValue?.fulfillments?.length > 0 && (
                <div>
                  <div>Fulfillments</div>
                  <Gaps />
                  {initialValue.fulfillments?.length > 0 &&
                    initialValue.fulfillments.map((item) => (
                      <div key={item.id}>
                        <Row gutter={[16, 16]}>
                          <Col>{item?.name}</Col>
                          <Col>{item?.tracking_company_name}</Col>
                          <Col>
                            {item.tracking_url ? (
                              <a
                                href={item.tracking_url}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'underline' }}
                              >
                                {item?.tracking_number}
                                {item?.tracking_number && <LinkOutlined />}
                              </a>
                            ) : (
                              item?.tracking_number
                            )}
                          </Col>
                        </Row>
                        {/* <Table dataSource={item.line_items} columns={fulfillmentCol} />
                        <Gaps /> */}
                      </div>
                    ))}
                </div>
              )}

              <Gaps />
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </div>
  )
}

export default PurchaseOrderForm
