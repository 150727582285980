import React, { useContext, useEffect, useState } from 'react'
import { Table, Button, Empty, Modal, message, Row, Col, Dropdown, Menu, Space, Card } from 'antd'
import { CaretDownOutlined, LinkOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/order_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import PurchaseOrderService from '../../network/services/purchaseOrder'
import { poSelectContext } from '.'

const PurchaseOrderTable = ({ total, overridePage, status }) => {
  const navigate = useNavigate()
  const { poIds, setPoIds } = useContext(poSelectContext)
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [visible, setVisible] = useState(false)

  const { data: response } = useSWR(
    serialize(PurchaseOrderService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      status: status,
      ...state.filters
    })
  )
  const rows = PurchaseOrderService.toRow(response)

  const rowSelection = {
    selectedRowKeys: poIds,
    onChange: (keySelected) => {
      //setSelectedRowKeys(keySelected)
      setPoIds(keySelected)
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: true
    },
    {
      title: 'Email',
      dataIndex: 'receiver_email',
      key: 'receiver_email',
      sorter: true
    },
    {
      title: 'Items',
      key: 'items',
      render: (record) => (
        <Space direction="vertical">
          {(record.items?.length ?? 0) > 0 &&
            record.items.slice(0, 3).map((item) => (
              <Card bodyStyle={{ fontSize: 12, padding: 3 }}>
                <p style={{ fontWeight: 'bold' }}>{item.sku}</p>
                {`${item?.product_variant?.fullname}`}
              </Card>
            ))}
          {(record.items?.length ?? 0) > 3 && <span>...</span>}
        </Space>
      )
    },
    {
      title: 'Tracking Code',
      key: 'tracking',
      render: (record) => (
        <Space direction="vertical">
          {record.fulfillments?.length > 0 &&
            record.fulfillments.map((item) =>
              item.tracking_url ? (
                <a
                  href={item.tracking_url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  {item?.tracking_number}
                  {item?.tracking_number && <LinkOutlined />}
                </a>
              ) : (
                item?.tracking_number
              )
            )}
        </Space>
      )
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
      // sorter: true
    }
  ]

  const filterColumns = [
    { key: 'id', name: 'ID' },
    { key: 'sku', name: 'SKU' },
    { key: 'status', name: 'Status' },
    { key: 'name', name: 'Name' },
    { key: 'receiver_email', name: 'Email' },
    { key: 'tracking', name: 'Tracking Code' },
    { key: 'address', name: 'Address' }
  ]
  filterColumns.forEach((item) => {
    if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  const dropdowns = [
    // {
    //   key: 'fulfillment_status_id',
    //   width: 'auto',
    //   label: 'Fulfillment Status',
    //   value: [{ key: '', name: 'All' }, ...fulfillmentStatuses]
    // },
    // {
    //   key: 'transaction_status_id',
    //   width: 'auto',
    //   label: 'Transaction Status',
    //   value: [{ key: '', name: 'All' }, ...transactionStatuses]
    // }
  ]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  return (
    <>
      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete">Delete</Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <TableFilter
            dropdowns={dropdowns}
            columns={filterColumns}
            hasDate={false}
            setCurrentFilters={setCurrentFilters}
          />
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter) => {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current
            tableStore.state.pageSize = pagination.pageSize
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter([...selectedRowKeys], function (o) {
                      return o !== record.id
                    })
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              } // click row
            }
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default PurchaseOrderTable
